import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";


const IndexPage = () => (
    <div className="index">
        <SEO title="Happy Indra Wijaya" />
        <div className="title">
            <Link to="/">
                <h1>I'm <br/> Happy Indra Wijaya_</h1>
            </Link>
            <span>a software engineer</span>
        </div>

        <div className="menu">
            <Link to="/blog">/blog</Link>
            <Link to="/about">/about</Link>
        </div>
    </div>
);
export default IndexPage;